// import thumb1 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/1.png";
// import thumb2 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/2.png";
// import thumb3 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/3.png";
// import thumb4 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/4.png";
// import thumb5 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/5.png";
// import thumb6 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/6.png";
// import thumb7 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/7.png";
// import thumb8 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/8.png";
// import thumb9 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/9.png";
// import thumb10 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/10.png";
// import thumb11 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/11.png";
// import thumb12 from "https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/12.png";

import thumb1 from "../images/grit/story_4600.png";
import thumb2 from "../images/grit/story_4600.png";
import thumb3 from "../images/grit/story_4600.png";
import thumb4 from "../images/grit/story_4600.png";
import thumb5 from "../images/grit/story_4600.png";
import thumb6 from "../images/grit/story_4600.png";
import thumb7 from "../images/grit/story_4600.png";
import thumb8 from "../images/grit/story_4600.png";
import thumb9 from "../images/grit/story_4600.png";
import thumb10 from "../images/grit/story_4600.png";
import thumb11 from "../images/grit/story_4600.png";
import thumb12 from "../images/grit/story_4600.png";

// let thumbs_index = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
// let thumbs = thumbs_index.map(function(val){return `https://ipfs.muzeplatform.com/ipfs/QmQ7kbFoLS4JcwJK887kC9RydS5uBcSyR6NcpEEdPUvxTM/${var}.png`;});

// export const products = [
//   {
//     thumb: thumbs[1],
//     title: "Petcat #256",
//     price: "0.25 ETH",
//     likes: "124",
//   },
//   {
//     thumb: thumbs[2],
//     title: "Petcat #257",
//     price: "0.35 ETH",
//     likes: "123",
//   },
//   {
//     thumb: thumbs[3],
//     title: "Petcat #259",
//     price: "0.50 ETH",
//     likes: "150",
//   },
//   {
//     thumb: thumbs[4],
//     title: "Petcat #260",
//     price: "0.65 ETH",
//     likes: "165",
//   },
//   {
//     thumb: thumbs[5],
//     title: "Petcat #261",
//     price: "0.85 ETH",
//     likes: "185",
//   },
//   {
//     thumb: thumbs[6],
//     title: "Petcat #262",
//     price: "1.00 ETH",
//     likes: "210",
//   },
//   {
//     thumb: thumbs[7],
//     title: "Petcat #263",
//     price: "1.15 ETH",
//     likes: "225",
//   },
//   {
//     thumb: thumbs[8],
//     title: "Petcat #264",
//     price: "1.35 ETH",
//     likes: "235",
//   },
//   {
//     thumb: thumbs[9],
//     title: "Petcat #265",
//     price: "1.50 ETH",
//     likes: "250",
//   },
//   {
//     thumb: thumbs[10],
//     title: "Petcat #266",
//     price: "2.50 ETH",
//     likes: "265",
//   },
//   {
//     thumb: thumbs[11],
//     title: "Petcat #267",
//     price: "2.85 ETH",
//     likes: "270",
//   },
//   {
//     thumb: thumbs[12],
//     title: "Petcat #268",
//     price: "3.10 ETH",
//     likes: "345",
//   },
// ];


export const products = [
  {
    thumb: thumb1,
    title: "Petcat #256",
    price: "0.25 ETH",
    likes: "124",
  },
  {
    thumb: thumb2,
    title: "Petcat #257",
    price: "0.35 ETH",
    likes: "123",
  },
  {
    thumb: thumb3,
    title: "Petcat #259",
    price: "0.50 ETH",
    likes: "150",
  },
  {
    thumb: thumb4,
    title: "Petcat #260",
    price: "0.65 ETH",
    likes: "165",
  },
  {
    thumb: thumb5,
    title: "Petcat #261",
    price: "0.85 ETH",
    likes: "185",
  },
  {
    thumb: thumb6,
    title: "Petcat #262",
    price: "1.00 ETH",
    likes: "210",
  },
  {
    thumb: thumb7,
    title: "Petcat #263",
    price: "1.15 ETH",
    likes: "225",
  },
  {
    thumb: thumb8,
    title: "Petcat #264",
    price: "1.35 ETH",
    likes: "235",
  },
  {
    thumb: thumb9,
    title: "Petcat #265",
    price: "1.50 ETH",
    likes: "250",
  },
  {
    thumb: thumb10,
    title: "Petcat #266",
    price: "2.50 ETH",
    likes: "265",
  },
  {
    thumb: thumb11,
    title: "Petcat #267",
    price: "2.85 ETH",
    likes: "270",
  },
  {
    thumb: thumb12,
    title: "Petcat #268",
    price: "3.10 ETH",
    likes: "345",
  },
];

export const categories = [
  {
    widgetTitle: "BACKGROUND",
    items: [
      {
        title: "blue",
        products: "23",
      },
      {
        title: "green",
        products: "18",
      },
      {
        title: "pink",
        products: "13",
      },
      {
        title: "red",
        products: "24",
      },
      {
        title: "rainbow",
        products: "8",
      },
      {
        title: "yellow",
        products: "57",
      },
    ],
  },
  {
    widgetTitle: "CLOTHES",
    items: [
      {
        title: "Denim",
        products: "67",
      },
      {
        title: "Black Hoodie",
        products: "35",
      },
      {
        title: "Sushi",
        products: "43",
      },
      {
        title: "Hawaii Shirt",
        products: "27",
      },
    ],
  },
  {
    widgetTitle: "Face",
    items: [
      {
        title: "pretty",
        products: "105",
      },
      {
        title: "Happy Smile",
        products: "78",
      },
      {
        title: "Blushing",
        products: "33",
      },
      {
        title: "Yummy",
        products: "41",
      },
    ],
  },
  {
    widgetTitle: "Eye",
    items: [
      {
        title: "pretty",
        products: "105",
      },
      {
        title: "Happy Smile",
        products: "78",
      },
      {
        title: "Blushing",
        products: "33",
      },
      {
        title: "Yummy",
        products: "41",
      },
    ],
  },
];
