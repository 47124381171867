import { useModal } from "../utils/ModalContext";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/header";
import Layout from "../common/layout";
import Banner from "../components/banner";
import CharacterSlider from "../components/characterSlider";
import HowToMint from "../components/howToMint";
import About from "../components/about";
import RoadMap from "../components/roadMap";
import Team from "../components/team";
import FAQ from "../components/faq"; 
import Footer from "../components/footer";
import MintNowModal from "../common/modal/mintNowModal";
import WalletModal from "../common/modal/walletModal/WalletModal";
import MetamaskModal from "../common/modal/metamask/MetamaskModal";
// import ConnectWallet from "../common/modal/metamask/ConnectWallet";

const Home = () => {
  // const { visibility, walletModalvisibility, metamaskModalVisibility, connectWalletModal } = useModal();
  const { visibility, walletModalvisibility, metamaskModalVisibility } = useModal();
  return (
    <Layout id="home">
       <GlobalStyles />  
      {visibility && <MintNowModal />}
      {walletModalvisibility && <WalletModal />}
      {metamaskModalVisibility && <MetamaskModal/> }
      {/* {connectWalletModal && <ConnectWallet/> } */}
      <Header />
      <Banner />
      {/* <Counter /> */}
      <CharacterSlider />
      <HowToMint />
      <About />
      <RoadMap /> 
      <Team />
      <FAQ />
      <Footer />
    </Layout>
  );
};

export default Home;
