// import contract from '../contracts/bithuabi.json';
// import contract from '../contracts/BithuContract.json';
import contract from '../contracts/GritContract.json';
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum } from '../config';



export const mint = async (mint_amount) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        // const contractAddress = "0x9FD56e423c9f0C01D4d011ad30860962ddaeA51D";
        // const contractAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3"; # localhost
        // const contractAddress = "0xEd75A99F2dbfF2d8fA953516b3175fffe5092668"; // Bithu goerli 
        const contractAddress = "0xaAF154d6492004fDc15dd229367ad4896bd6e294"; // Grit goerli
        
        const nftContract = new ethers.Contract(contractAddress, contract.abi, signer);
        let txnHash = await nftContract.mint(ethereum.selectedAddress, mint_amount, {
            // gasLimit: "285000",
            gasLimit: "1000000",
            value: ethers.utils.parseEther((0.01 * mint_amount).toString())
        })
        return txnHash
    }
}

export const totalMintCount = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        // const contractAddress = "0x9FD56e423c9f0C01D4d011ad30860962ddaeA51D";
        // const contractAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3"; # localhost
        // const contractAddress = "0xEd75A99F2dbfF2d8fA953516b3175fffe5092668"; // Bithu goerli 
        const contractAddress = "0xaAF154d6492004fDc15dd229367ad4896bd6e294"; // Grit goerli

        const nftContract = new ethers.Contract(contractAddress, contract.abi, signer);

        let totalMint = await nftContract.count();

        return totalMint;
    }
}
