import { useModal } from "../utils/ModalContext";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/header";
import PageHeader from "../common/pageHeader/v4";
import Collection from "../components/collection";
// import CTA from "../components/section/cta/v2";
import Footer from "../components/footer";
// import MetamaskModal from "../common/modal/metamaskModal/MetamaskModal"; 
import MetamaskModal from "../common/modal/metamask/MetamaskModal";
import WalletModal from "../common/modal/walletModal/WalletModal";

const CollectionsPage = () => {
  const { walletModalvisibility, metamaskModalVisibility} = useModal();

  return (
    <>
      <Layout>
        <GlobalStyles /> 
        {metamaskModalVisibility && <MetamaskModal />}
        {walletModalvisibility && <WalletModal />}
        <Header />
        <PageHeader />
        <Collection />
        <Footer />
      </Layout>
    </>
  );
};

export default CollectionsPage;
