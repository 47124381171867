// import { FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa";

// import avatarThumb1 from "../../images/team/v2_team_1.png"; 
// // import avatarThumb2 from "../../images/team/v2_team_2.png"; 
// import avatarThumb3 from "../../images/team/v2_team_3.png"; 
// import avatarThumb4 from "../../images/team/v2_team_4.png"; 
import avatarThumb1 from "../../images/team/crew_avatar6.png"; 
// import avatarThumb2 from "../../images/team/v2_team_2.png"; 
import avatarThumb3 from "../../images/team/crew_avatar7.png"; 
import avatarThumb4 from "../../images/team/crew_avatar4.png"; 

const data = [
  {
    avatar: avatarThumb1,
    name: "Jay Kim",
    designation: "Founder",
    // socialLinks: [
      // {
      //   icon: <FaLinkedinIn />,
      //   url: "#",
      // },
      // {
      //   icon: <FaTwitter />,
      //   url: "#",
      // },
      // {
      //   icon: <FaInstagram />,
      //   url: "#",
      // },
    // ],
  },    
  // {
  //   avatar: avatarThumb2,
  //   name: "Nathan Bean",
  //   designation: "Co-Founder",
  //   socialLinks: [
  //     {
  //       icon: <FaLinkedinIn />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FaTwitter />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FaInstagram />,
  //       url: "#",
  //     },
  //   ],
  // },    
  {
    avatar: avatarThumb3,
    name: "Park So Yun",
    designation: "Artist",
    // socialLinks: [
    //   {
    //     icon: <FaLinkedinIn />,
    //     url: "#",
    //   },
    //   {
    //     icon: <FaTwitter />,
    //     url: "#",
    //   },
    //   {
    //     icon: <FaInstagram />,
    //     url: "#",
    //   },
    // ],
  },    
  {
    avatar: avatarThumb4,
    name: "Shin Yong Ho",
    designation: "Software Engineer",
    // socialLinks: [
    //   {
    //     icon: <FaLinkedinIn />,
    //     url: "#",
    //   },
    //   {
    //     icon: <FaTwitter />,
    //     url: "#",
    //   },
    //   {
    //     icon: <FaInstagram />,
    //     url: "#",
    //   },
    // ],
  },    
];

export default data;
