import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Collections from "./pages/collections";
// import logo from './logo.svg';
// import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/collections" element={<Collections />} />
    </Routes>
  );
}

export default App;
