import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
// import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    thumb: openseaIcon,
    url: "https://testnets.opensea.io/",
  },
  // {
  //   icon: <FaTwitter />,
  //   url: "#",
  // },
  // {
  //   icon: <FaLinkedinIn />,
  //   url: "#",
  // },
  // {
  //   icon: <FaFacebook />,
  //   url: "#",
  // },
  // {
  //   icon: <FaInstagram />,
  //   url: "#",
  // },
  // {
  //   icon: <FaTelegramPlane />,
  //   url: "#",
  // },
  // {
  //   thumb: mediumIcon,
  //   url: "#",
  // },
];

export default data;
