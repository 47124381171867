// import characterThumb1 from "../../images/nft/carousel_avater1.png";
// import characterThumb2 from "../../images/nft/carousel_avater2.png";
// import characterThumb3 from "../../images/nft/carousel_avater3.png";
// import characterThumb4 from "../../images/nft/carousel_avater4.png";
// import characterThumb5 from "../../images/nft/carousel_avater5.png";
// import characterThumb6 from "../../images/nft/carousel_avater6.png";

import characterThumb1 from "../../images/grit/carousel_avater_1000.png";
import characterThumb2 from "../../images/grit/carousel_avater_2000.png";
import characterThumb3 from "../../images/grit/carousel_avater_3000.png";
import characterThumb4 from "../../images/grit/carousel_avater_4000.png";
import characterThumb5 from "../../images/grit/carousel_avater_5000.png";
import characterThumb6 from "../../images/grit/carousel_avater_6000.png";
import characterThumb7 from "../../images/grit/carousel_avater_7000.png";
import characterThumb8 from "../../images/grit/carousel_avater_8000.png";
import characterThumb9 from "../../images/grit/carousel_avater_9000.png";

const data = [
  {
    thumb: characterThumb1,
  },
  {
    thumb: characterThumb2,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb4,
  },
  {
    thumb: characterThumb5,
  },
  {
    thumb: characterThumb6,
  },
  {
    thumb: characterThumb7,
  },
  {
    thumb: characterThumb8,
  },
  {
    thumb: characterThumb9,
  },
];

export default data;
