import Button from "../../common/button";
import AboutStyleWrapper from "./About.style";

// import thumb1 from "../../assets/images/nft/story-img1.png";
// import thumb2 from "../../assets/images/nft/story-img2.png";
// import thumb3 from "../../assets/images/nft/story-img3.png";
// import thumb4 from "../../assets/images/nft/story-img4.png";
import thumb1 from "../../assets/images/grit/story_2600.png";
import thumb2 from "../../assets/images/grit/story_4600.png";
import thumb3 from "../../assets/images/grit/story_6600.png";
import thumb4 from "../../assets/images/grit/story_7600.png";

const About = () => {
  return (
    <AboutStyleWrapper id="about">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="about_left_content">
              <h2 className="about_title">
                THE STORY <br /> 
              </h2>
              <p>
              광대하고 무한한 우주의 많은 행성에는 지적인 존재가 살고 
있습니다. GRIT(Generic Regenerated Intellectual Tribes :  
유전자 재합성된 지적 존재들)은 지구상에 존재하는 생명체의
유전자를 합성하여 만든 특별한 능력과 지능을 부여받은
 독특한 초능력자들입니다.
지구는 지적생명체가 지배하는 행성 중 하나인데 인간이 지적
생명체의 주종을 이루고 있습니다.
그러나 다른 행성에서의 이야기는 사뭇 다릅니다. 유다릭스
행성에서는 지적인 호랑이 종족이 행성을 지배합니다. 또한 
지적인 사자가 지배하는 레오니지아 행성도 있습니다.
이 GRIT들은 각각의 행성의 지적 존재들의 혼합된 DNA 특성을 
가지고 있으면 메타버스세상에 그들의 영역을 확장해 갑니다.

창조주 갤라수스의 첫글자 G를 딴 그릿은 지구상 생명체의 DNA
를 조합하여 만들어졌습니다. 그릿은 행성과 행성을 여행하며
새로운 도전과 모험을 찾아 떠납니다. 그릿은 새로운 독특한 
능력과 문화를 가진 또 다른 그릿을 만나고 함께 멀티우주와 
끝없는 가능성을 탐험해 나갑니다.

그릿이 여행할 때 그릿과 동행자들은 놀라움과 위험이 숨겨져
있는 새로운 우주를 발견합니다. 그들은 그들이 가지고 있는
힘과 지능과 그리고 우정을 시험하는 도전을 직면하지만 그릿들은
참고 견디면서 우주와 그 우주 주민들에 대해 배워 나갑니다.

그릿은 메타버스세상에 전설이 되어 여러 지적인 부족들 사이의 
통일과 협의 상징이 됩니다. 비록 다른 부족들로 부터 합셩되어
창조되었지만 그들은 모두 더 나은 우주의 미래를 위해 협력해 나아갈
것입니다. 
              </p>
              <p>
              In the vast and infinite universe, many planets are home to intelligent beings. These beings, known as the Generic Regenerated Intellectual Tribes, or GRIT, possess the supernatural powers to mix their DNA with various species living in Earth, granting them extraordinary abilities and intelligence. The Earth is one of these planets, where humans have risen to become the dominant species.
However, on other planets, the story is vastly different. In the planet Utharix, Intelligent tigers rule one world, while intelligent lions control another planet named Leonisia. These GRITs have the mixed DNA charateristics of the respective planets' intellectual beings and continue to extend their reach into the metaverse world.
One such GRIT, named after its creator, Galaxus's Initail 'G', has the DNA of all living things on Earth. It travels from planet to planet, seeking new challenges and adventures. It encounters various GRITs, each possessing their own unique abilities and cultures, and together they explore the multiverse and its endless possibilities.
As they travel, GRIT and its companions discover that the universe is filled with both wonder and danger. They face challenges that test their strength, intelligence, and friendship, but they persevere and continue to learn more about the universe and its inhabitants.
GRIT becomes a legend among the metaverse, a symbol of unity and cooperation between the different intelligent tribes. It serves as a reminder that even though they may be created from different tribes, they can all come together to create a better future for all.
              </p>

              <Button lg variant="mint">
                Read Litepaper
              </Button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about_right_content">
              <div className="nft_thumb">
                <img src={thumb1} alt="thumb" />
              </div>
              <div className="nft_thumb">
                <img src={thumb2} alt="thumb" />
              </div>
              <div className="nft_thumb">
                <img src={thumb3} alt="thumb" />
              </div>
              <div className="nft_thumb">
                <img src={thumb4} alt="thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
